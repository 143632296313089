import React from "react"
import { graphql } from "gatsby"

import { DefaultLayout } from "../layouts/Default"
import { SEO } from "../components/SEO"
import { PostItem } from "../components/PostList"
import { CourseCard, CourseGrid } from "../components/CourseCard"

const Topic = ({ data }) => {
  const title = `Topic: "${data?.topic.name}" - React Native School`
  const description = `Learn more about ${data?.topic.name} at React Native School.`

  const containerClasses = "container mx-auto px-8 max-w-screen-md mt-10"

  const courses = data?.courses?.edges || []

  const posts = data?.posts?.edges || []

  return (
    <DefaultLayout>
      <SEO title={title} description={description} />

      <div>
        <div className={containerClasses}>
          <h1 className="text-2xl font-semibold leading-tight text-gray-800">
            {title}
          </h1>
        </div>
        {courses.length > 0 && (
          <div className="">
            <CourseGrid>
              {courses.map(({ node }) => (
                <CourseCard
                  key={node.slug}
                  slug={`/${node.slug}/`}
                  title={node.title}
                  price={node.price}
                  description={node.description}
                  updated={node.lastUpdated}
                />
              ))}
            </CourseGrid>
          </div>
        )}
        <div className={containerClasses}>
          {posts.map(({ node: { frontmatter } }) => (
            <PostItem key={frontmatter.slug} {...frontmatter} />
          ))}
        </div>
      </div>
    </DefaultLayout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    topic: topicsYaml(slug: { eq: $slug }) {
      name
    }

    posts: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/blog/**" }
        frontmatter: {
          published: { eq: true }
          topics: { elemMatch: { slug: { in: [$slug] } } }
        }
      }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            date
            slug
          }
        }
      }
    }

    courses: allSanityClass(
      filter: { topics: { in: [$slug] }, visible: { eq: true } }
    ) {
      edges {
        node {
          title
          description
          lastUpdated
          price
          slug
        }
      }
    }
  }
`

export default Topic
